<template>
  <div class="all-height d-flex align-center justify-center">
    <v-card min-width="600" :loading="loading" :disabled="loading" loader-height="2">
      <v-card-text>
        <div>
          <lb-string label="Client Name" :value="$nova.clientName()" :error="errors['client']" :disabled="true" />
          <div class="d-flex">
            <lb-date label="Start Date" v-model="data['from']" :error="errors['from']"/>
            <span class="mr-4"></span>
            <lb-date label="End Date" v-model="data['to']" :error="errors['to']"/>
          </div>
          <div class="text-center">
            <v-btn v-if="type === 'add'" color="primary" @click="save()" elevation="0">Create</v-btn>
            <v-btn v-else-if="type === 'edit'" color="primary" @click="update()" elevation="0">Update</v-btn>
            <v-btn @click="$router.go(-1);" text class="mr-2">Cancel</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
// import pageHeader from '@/components/pageHeader'
// import managelist from '@/components/ManageList'

export default {
  name: 'engagement_list',
  // props: ['props'],
  data: function(){
    return {
      loading: false,
      type: "add",
      data: {},
      errors: {},
    }
  },
  // components: {
  //   // pageHeader,managelist
  // },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      if(this.$store.state.client !== ''){
        this.errors = {};
        if(((this.$route.meta) || {}).type === "edit") this.type = "edit";
        else this.type = "add";
      }
    },
    save(){
      this.errors = {};
      this.loading = true;
      this.axios.post("/v2/ia/engagement/add", {data: [this.data]}).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$nova.gotoLink({path: '/ia/engagement/view/'+dt.data.data[0]._id});
        }else{
          this.errors = {};
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
          }
          console.log("Error adding engagement");
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    update(){
      if(this.data._id){
        this.axios.post("/v2/ia/engagement/edit", {data: [this.data], id: this.data._id}).then(dt => {
          if(dt.data.status === "success"){
            this.refreshData();
            this.$store.commit("refreshClient", true);
          }else{
            this.errors = {};
            if (dt.data.data.length > 0){
              if(dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            console.log("Error updating client");
          }
        })
      }else console.log("No ID found to update");
    },
    clientName: function(d){
      let n = "";
    for (const i of this.selectdata.client) {
       if(i._id === d){
          n = i.name;
          break;
        }
    }
      return n;
    }
  },
  watch: {
    "$store.state.client": function(){
      this.selectedindex = -1;
      this.modifydata = {};
      this.addflag = false;
      this.editflag = false;
      this.refreshData();
    }
  }
}
</script>
